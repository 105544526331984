import Swiper, {Navigation} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperOther = new Swiper(".slider-other", {
    modules: [Navigation],
    slidesPerView: 1, 
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".other-next",
      prevEl: ".other-prev",
    }, 
    breakpoints: {
        580: {
            slidesPerView: 2, 
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
  });
}
  
