import Swiper, {Navigation,EffectFade,Autoplay} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperBanner = new Swiper(".slider-banner", {
    modules: [Navigation,Autoplay],
    slidesPerView: 1, 
    loop: true,
    effect: 'fade',
    // autoplay: {
    //   delay: 5000,
    // },
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    }, 
  });
}
  
