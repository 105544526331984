import Swiper, {Navigation} from "../../assets/.npm/node_modules/swiper";

export default () => {

    let swiperProject = new Swiper(".slider-project", {
        modules: [Navigation],
        slidesPerView: 1, 
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: ".project-next",
            prevEl: ".project-prev",
        },
        breakpoints: {
            580: {
                slidesPerView: 2, 
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });
}
  
