import Swiper, {Navigation} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperPartner = new Swiper(".slider-partner", {
    modules: [Navigation],
    slidesPerView: 2, 
    spaceBetween: 20,
    loop: true,
    navigation: {
        nextEl: ".partner-next",
        prevEl: ".partner-prev",
    },
    breakpoints: {
      580: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 6,
      },
    },
  });
}
  
