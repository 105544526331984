export default () => {
    
    var $filter = $('.product-page__tab');
    var $tab = $('.product-page__tab a');
    var $offers = $('.product-page__content .card-product-col');


    $tab.click(function(e) {
        var ourClass = $(this).attr('class');
        e.preventDefault();
        $tab.removeClass('current');
        $(this).addClass('current');
        

        if (ourClass == 'all') {
            $offers.show();
        } else {
            $offers.show()
            $('.product-page__content .card-product-col:not(.' + ourClass + ')').hide();
            $('product-page__content .card-product-col.' + ourClass).show();
        }
        return false;
    });
}