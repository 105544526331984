import Swiper, {Navigation} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperService = new Swiper(".slider-service", {
    modules: [Navigation],
    slidesPerView: 1, 
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".service-next",
      prevEl: ".service-prev",
    }, 
    breakpoints: {
        580: {
            slidesPerView: 2, 
        },
        992: {
            slidesPerView: 3.2,
            spaceBetween: 30,
        },
    },
  });
}
  
