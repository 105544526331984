import Swiper, {Navigation,Grid} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperP1 = new Swiper(".slider-p1", {
    modules: [Navigation,Grid],
    slidesPerView: 1,
    grid: {
        rows: 3,
        fill: 'row'
    },
    spaceBetween: 30,
    navigation: {
        nextEl: ".p1-next",
        prevEl: ".p1-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        grid: {
            rows: 2,
            fill: 'row'
        },
      },
      992: {
        slidesPerView: 3,
        grid: {
            rows: 2,
            fill: 'row'
        },
      },
    },
  });

  let swiperP2 = new Swiper(".slider-p2", {
    modules: [Navigation,Grid],
    slidesPerView: 1,
    grid: {
        rows: 3,
        fill: 'row'
    },
    spaceBetween: 30,
    navigation: {
        nextEl: ".p2-next",
        prevEl: ".p2-prev",
    },
    breakpoints: {
        768: {
        slidesPerView: 2,
        grid: {
            rows: 2,
            fill: 'row'
        },
      },
      992: {
        slidesPerView: 3,
        grid: {
            rows: 2,
            fill: 'row'
        },
      },
    },
  });
}
  
