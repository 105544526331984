import Swiper, {Pagination,Grid} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperTaxoPartner = new Swiper(".slider-taxo-partner", {
    modules: [Pagination,Grid],
    slidesPerView: 2,
    grid: {
        rows: 2,
        fill: 'row'
    },
    spaceBetween: 24,
    pagination: {
        el: '.taxo-partner-pagination',
        clickable: true,
      },
    breakpoints: {
      768: {
        slidesPerView: 2,
        grid: {
            rows: 3,
            fill: 'row'
        },
      },
      992: {
        slidesPerView: 4,
        grid: {
            rows: 3,
            fill: 'row'
        },
      },
    },
  });

  
}
  
