

export default () => {
    $(window).on('load', function(){
        if($('.taxo-content').length > 0){
            let wrap = $('.taxo-content__des');
            let current_height = wrap.height();
            let your_height = 300;
            if(current_height > your_height){
                wrap.addClass('fix-height');
                wrap.append(function(){
                    return '<div class="taxo-content__show"><a title="Xem thêm" href="javascript:void(0);">Xem thêm</a></div>';
                });
                wrap.append(function(){
                    return '<div class="taxo-content__hide" style="display: none;"><a title="Thu gọn" href="javascript:void(0);">Thu gọn</a></div>';
                });
                $('body').on('click','.taxo-content__show', function(){
                    wrap.removeClass('fix-height');
                    $('body .taxo-content__show').hide();
                    $('body .taxo-content__hide').show();
                });
                $('body').on('click','.taxo-content__hide', function(){
                    wrap.addClass('fix-height');
                    $('body .taxo-content__hide').hide();
                    $('body .taxo-content__show').show();
                });
            }
        }
    });
}
  
