import Swiper, {Navigation} from "../../assets/.npm/node_modules/swiper";

export default () => {
  if($(".slider-child").length) {
    let swiperChild = new Swiper(".slider-child", {
      modules: [Navigation],
      slidesPerView: 2, 
      spaceBetween: 16,
      loop: true,
      navigation: {
          nextEl: ".child-next",
          prevEl: ".child-prev",
      },
      breakpoints: {
        601: {
          slidesPerView: 4,
        },
        992: {
          slidesPerView: 6,
        },
      },
    });
  }
}
  
