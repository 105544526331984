import Swiper, {Pagination,Autoplay} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperBanner = new Swiper(".slider-taxo", {
    modules: [Pagination,Autoplay],
    slidesPerView: 1, 
    loop: true,
    // autoplay: {
    //   delay: 5000,
    // },
    pagination: {
        el: '.taxo-pagination',
        clickable: true,
      },
  });

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    let height = $(".header").outerHeight();
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - height
    }, 100);
  });
}
  
