import Swiper, {Pagination} from "../../assets/.npm/node_modules/swiper";

export default () => {
  let swiperTaxCus = new Swiper(".slider-taxo-customer", {
    modules: [Pagination],
    slidesPerView: 1, 
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: '.taxo-customer-pagination',
        clickable: true,
      },
    breakpoints: {
      580: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });
}
  
